import React from 'react';
import ReactDOM from 'react-dom';

function Home () {
    return (<>
	<h1> SSL HAHAHAHAAHAHAHAHAH </h1>
	<img src="https://imagens.brasil.elpais.com/resizer/SZ65z4Uo235xp57U4cEtbGulYQA=/980x0/cloudfront-eu-central-1.images.arcpublishing.com/prisa/76F4QDUQQL6CGBUEANME3GDBVY.jpg"/>
	<p> sim foi o manoel, estudante de redes turma trc341 que fez</p>
	</>)
}


ReactDOM.render(
    <Home />,
    document.getElementById('root')
  );
  
